import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rem } from 'polished'
import Link from '@/components/Link'
import { mq } from '@/styles/theme'

export const col = (numCols = 1, gap = `0 var(--d-gutter)`) => css`
  display: grid;
  grid-template-columns: repeat(${numCols}, 1fr);
  grid-auto-flow: column;
  grid-gap: ${gap};
`

export const dynamicCol = css`
  display: grid;

  ${mq.greaterThan('small')`
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: var(--d-gutter);
  `};
`

export const dynamicRow = css`
  display: grid;

  ${mq.greaterThan('small')`
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-gap: var(--d-gutter);
  `};
`

export const ParentLayoutStyles = css`
  background: var(--c-veryLightPink);
  padding: 50px 0 0;
`

export const SidebarLayoutStyles = css`
  display: grid;
  padding-bottom: 100px;
  grid-template-areas:
    'blog-header'
    'blog-main'
    'blog-more';

  ${mq.greaterThan('small')`
    grid-template-columns: 280px 1fr;
    grid-template-areas:
      'blog-header blog-main'
      'blog-header blog-more';
    grid-gap: 30px;
  `};
`

export const SectionBorder = css`
  border: solid 5px #fff;
`

export const TitleStyles = css`
  color: var(--c-gunmetal);
  font: ${rem('32px')} var(--f-serif);
  letter-spacing: ${rem('-1.15px')};
  line-height: 1.25;
  margin: 0 0 25px;

  ${mq.greaterThan('small')`
    font-size: ${rem('46px')};
  `};
`

export const NoteStyles = css`
  color: var(--c-gunmetal);
  font-size: ${rem('14px')};
  line-height: 1.5;
`

export const PreHeaderStyles = css`
  font: 600 ${rem('14px')} var(--f-sans);
  letter-spacing: ${rem('2px')};
  line-height: 1.5;
  text-transform: uppercase;
`

export const SplitLayoutStyles = css`
  display: grid;
  grid-gap: var(--d-gutter);
  margin-bottom: 60px;

  ${mq.greaterThan('small')`
    grid-template-columns: 4.5fr 5.5fr;
    grid-auto-flow: column;
  `};
`

export const AccentRuleStyles = (opts = {}) => {
  const { justify, color, top } = opts

  return css`
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 55px;
      height: 5px;
      background: ${color || 'var(--c-lightGreyBlue)'};
      position: absolute;
      top: ${top || '-25px'};

      ${!justify &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `}

      ${justify &&
      justify === 'left' &&
      css`
        left: 0;
        transform: none;
      `}
    }
  `
}

export const DescriptionStyles = css`
  margin: 0;
  font: normal ${rem('18px')} var(--f-sans);
  letter-spacing: ${rem('0.5px')};
  line-height: 1.5;
`

export const AbsoluteFill = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const FullPseudoBlockStyles = css`
  content: '';
  ${AbsoluteFill};
`

export const MediaAspectRatio = css`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;

  img,
  video,
  iframe {
    ${AbsoluteFill};
    object-fit: cover;
    border-radius: inherit;
    z-index: 1;
  }
`

export const IntroBodyStyles = css`
  color: var(--c-gunmetal);
  font-size: ${rem('18px')};
  line-height: 1.5;
  text-align: center;
  max-width: 575px;
  margin: 0 0 25px 0;
`

export const ResetButtonStyles = css`
  background: transparent;
  appearance: none;
  border: 0;
  outline: 0;
  cursor: pointer;
`

export const ButtonStyles = css`
  ${ResetButtonStyles};
  background: var(--c-canary);
  color: var(--c-charcoalGrey);
  font-family: var(--f-default);
  font-size: ${rem('18px')};
  font-weight: 600;
  letter-spacing: ${rem('2px')};
  line-height: 1.25;
  text-align: center;
  padding: 15px 25px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  box-sizing: border-box;

  &.wide {
    padding-left: 50px;
    padding-right: 50px;
  }

  &:visited {
    color: var(--c-charcoalGrey);
  }

  &.disabled {
    background: var(--c-lightGrey);
    cursor: not-allowed;
  }
`

export const AbsolutelyCenter = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const GradientOverlay = css`
  ${FullPseudoBlockStyles};
  pointer-events: none;
  background: linear-gradient(
    197.11deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 1;
`

export const MasonryLayoutStyles = css`
  ${mq.greaterThan('small')`
    column-count: 4;
    column-gap: var(--d-gutter);
    padding: 0;

    > *:first-of-type {
      margin-top: 0.375rem;
    }
  `}
`

export const BlogMetadataItem = styled.dl``
export const BlogMetadataLabel = styled.dt``
export const BlogMetadataValue = styled.dd`
  margin: 0;
`

export const BlogMetadataHeader = styled.h4`
  color: var(--c-charcoalGrey);
  font: 600 ${rem('18px')} var(--f-default);
  letter-spacing: ${rem('2px')};
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

export const BlogMetadataLink = styled(Link)`
  color: var(--c-lightGreyBlue);
  font-size: ${rem('24px')};
  line-height: 1.5;
  white-space: nowrap;
`

export const BlogPostImageCaption = styled.div`
  font-style: italic;
  text-align: center;
  font-size: 1rem;
`

export const LargeParagraph = styled.div`
  p:first-of-type {
    font-size: ${rem('18px')};

    ${mq.greaterThan('small')`
      font-size: ${rem('24px')};
    `};
    line-height: 1.5;
  }
`

export const ListHeaderStyles = css`
  color: var(--c-gunmetal);
  font: 600 ${rem('20px')} var(--f-serif);
  letter-spacing: ${rem('1.67px')};
  text-transform: uppercase;
`

export const BlogPostDate = styled.p`
  color: var(--c-lightGreyBlue);
  font: 600 ${rem('14px')} var(--f-default);
  letter-spacing: ${rem('2px')};
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;

  ${props =>
    props.featured &&
    css`
      ${mq.greaterThan('small')`
        color: #fff;
        margin-bottom: 0.5rem;
      `};
    `};
`
